<script setup lang="ts">
const props = defineProps<{
  date?: string
}>()

function displayDate(dateString: string) {
  const date = new Date(dateString)
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}
</script>

<template>
  <div v-if="props.date" class="text">
    {{ $t('Last updated on') }} : {{ displayDate(props.date) }}
  </div>
</template>

<style scoped>
.text {
  margin-top: 1.5em;
  font-style: italic;
  /* font-size: 0.9em; */
  color: #999;
}
</style>
